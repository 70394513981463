import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import { ProductPage } from '@talentinc/gatsby-theme-ecom/types/productPageV2'
import BaseLandingPageV2Template from '@talentinc/gatsby-theme-ecom/components/Templates/LandingPageV2'
import ProductPageSEO from '@talentinc/gatsby-theme-ecom/components/SEO/ProductPageSEO'
import { PageProductProvider } from '@talentinc/gatsby-theme-ecom/hooks/usePageProduct'
import NavTestHeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/NavTestHeaderV2'

const ProductPageTemplate: React.FC<
  PageProps<{ productPage: ProductPage } & PageDataContextType>
> = (props) => {
  const { productPage, ...pageData } = props.data
  return (
    <Layout pageTitle={productPage.title} pageData={pageData}>
      <PageProductProvider productPackage={productPage.product.productPackage}>
        <SEO landingPage={productPage} />
        {productPage.product.productPackage && (
          <ProductPageSEO landingPage={productPage} />
        )}
        <HeaderV2 />
        <NavTestHeaderV2 />
        <BaseLandingPageV2Template landingPage={productPage} />
      </PageProductProvider>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ProductPageBySlugAndBrand($slug: String!, $brandName: String!) {
    productPage: contentfulProductPage(
      slug: { eq: $slug }
      node_locale: { eq: "en-US" }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...ProductPage
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
      contentful_id: { eq: "3Nd6Keg87eW5pGryLZS2ZA" }
    ) {
      ...HeaderV2
    }
    navTestHeaderV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
      contentful_id: { eq: "6yZRtzPjZxKCylBbpXF0oH" }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ProductPageTemplate
